<script>
export default {
  props: {
    width: String,
    height: String
  },
  computed: {
    style() {
      return `${this.height}`;
    }
  }
};
</script>
<template>
  <!-- Loading untile data get set --->
  <div :class="this.width">
    <div class="card row">
      <div class="col-12 text-center p-5" :style="this.style">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
