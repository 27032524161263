var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: this.width }, [
    _c("div", { staticClass: "card row" }, [
      _c(
        "div",
        { staticClass: "col-12 text-center p-5", style: this.style },
        [
          _c("b-spinner", {
            staticClass: "m-2",
            staticStyle: { width: "5rem", height: "5rem" },
            attrs: { variant: "mid", role: "status" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }